import React from "react"
import ContactPageForm from "@webriq/gatsby-webriq-form"

const ContactForm = () => (
	<ContactPageForm
	 method="POST"
		data-form-id="3e169ffd-ffb3-4226-abed-cbd0ba5a2012"
		name="Contact Form"
		webriq="true"
        data-thankyou-url="/thank-you"
	>
		<div className="form-row">
			<div className="col-12">
				<div className="form-group">
					<input
						id="fullname"
						required
						type="text"
						name="Name"
						className="form-control"
						placeholder="Name"
					/>
					<label htmlFor="fullname">Name</label>
				</div>
			</div>
			<div className="col-12">
				<div className="form-group">
					<input
						id="emailadd"
						required
						type="email"
						name="Email"
						className="form-control"
						placeholder="Email Address"
					/>
					<label htmlFor="emailadd">Email Address</label>
				</div>
			</div>
			<div className="col-12">
				<div className="form-group">
					<input
						id="phonenumber"
						required
						type="phone"
						name="Phone Number"
						className="form-control"
						placeholder="Phone Number"
					/>
					<label htmlFor="phonenumber">Phone Number</label>
				</div>
			</div>
			<div className="col-12">
				<div className="form-group">
					<textarea
						id="message"
						required
						type="text"
						name="Message"
						className="form-control"
						rows={5}
						placeholder="How can we help you?"
					/>
					<label htmlFor="message">How can we help you?</label>
				</div>
			</div>
			<div className="col-12 mt-4">
				<div className="webriq-recaptcha" />
			</div>
			<div className="col-12">
				<div className="mt-4">
					<button
						id="submit"
						type="submit"
						className="btn btn-primary text-uppercase"
					>
						Send Message
					</button>
				</div>
			</div>
		</div>
		
	</ContactPageForm>
)

export default ContactForm
