import React from "react"
import Layout from "@components/layout/layout"
import SeoComponents from "@components/seo"
import PageHeading from "@components/pageHeading"
import HeadingBG from "@images/pexels-curtis-adams-4119836.jpeg"
import ContactForm from "@components/forms/contactForm"
import { Helmet } from "react-helmet"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const Contact = ({ location }) => {
  const ldjson = {
    "@context": "https://schema.org",
    "@type": "FurnitureStore",
    name: "Eden Oaks Designer Woodware",
    address: {
      "@type": "PostalAddress",
      streetAddress: "709 Nichols Blvd unit a",
      addressLocality: "Colorado Springs",
      addressRegion: "CO",
      postalCode: "80907",
    },
    image: "https://www.edenoakswoodware.com/img/newlogo-white.png",
    email: "freddie@edenoaksco.com",
    telePhone: "719-985-3336",
    url: "https://www.edenoakswoodware.com/",
    knowsAbout: [
      "https://en.wikipedia.org/wiki/Woodworking",
      "https://en.wikipedia.org/wiki/Millwork_(building_material)",
      "https://en.wikipedia.org/wiki/Cabinetry",
      "https://en.wikipedia.org/wiki/Furniture",
    ],
    paymentAccepted: ["cash", "check", "credit card"],
    openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "17:00",
      },
    ],
    geo: {
      "@type": "GeoCoordinates",
      latitude: "38.882089",
      longitude: "-104.811693",
    },
    hasMap:
      "https://www.google.com/maps/place/Eden+Oaks+Designer+Woodware/@38.8820742,-104.8144998,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x4544c624a2aba0a7!8m2!3d38.8820742!4d-104.8123111",
    AggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.6",
      ratingCount: "33",
    },
    sameAs: [
      "http://ablocal.com/us/colorado-springs-co/LX12711484-eden-oaks-designer-woodware/",
      "http://brownbook.net/business/44691022/eden-oaks-designer-woodware",
      "http://citysquares.com/b/eden-oaks-designer-woodware-14989944",
      "http://ezlocal.com/co/colorado-springs/furniture-store/097947960",
      "http://local.yahoo.com/info-220542747",
      "http://localpages.com/co/colorado-springs/lpd-42691031",
      "http://public.pr.business/eden-oaks-designer-woodware-colorado-springs-colorado",
      "http://socialseo.optimizelocation.com/partnerpages/airyell/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://socialseo.optimizelocation.com/partnerpages/aroundme/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://socialseo.optimizelocation.com/partnerpages/avantar/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://socialseo.optimizelocation.com/partnerpages/ckmaps/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://socialseo.optimizelocation.com/partnerpages/navmii/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://socialseo.optimizelocation.com/partnerpages/whiteandyellowpages/eden-oaks-designer-woodware-colorado-springs-colorado-us-8b5259",
      "http://voteforthebest.com/usa/colorado/colorado-springs/eden-oaks-designer-woodware/709-nichols-blvd-7192296245.html",
      "http://www.8coupons.com/discounts/eden-oaks-designer-woodware-colorado-springs-80910",
      "http://www.allonesearch.com/50667165",
      "http://www.americantowns.com/yext/listing/yx-10544303",
      "http://www.bing.com/maps?ss=ypid.YN873x8718891576670308257&mkt=en-US",
      "http://www.bizwiki.com/furniture-store/16889055/eden-oaks-designer-woodware.htm",
      "http://www.citysearch.com/profile/684078796/colorado_springs_co/eden_oaks_designer_woodware.html",
      "http://www.getfave.com/26818675-eden-oaks-designer-woodware",
      "http://www.ibegin.com/directory/visit/16483062",
      "http://www.icc.org/details/16889055.html",
      "http://www.insiderpages.com/b/15299755739",
      "http://www.local.com/business/details/colorado-springs-co/eden-oaks-designer-woodware-148202380.aspx",
      "http://www.mapquest.com/places/-420972917/",
      "http://www.n49.com/biz/2916502/eden-oaks-designer-woodware-co-colorado-springs-709-nichols-blvd/",
      "http://www.pointcom.com/business/41503751",
      "http://www.tupalo.co/colorado-springs-colorado/eden-oaks-designer-woodware",
      "http://www.yellowmoxie.com/509793675.ym",
      "http://www.yellowpagecity.com/US/CO/Colorado+Springs/Furniture+Dealers/Eden+Oaks+Designer+Woodware/246603890/",
      "http://www.yellowpages.com/colorado-springs-co/mip/eden-oaks-designer-woodware-548768460?from=yext",
      "https://coloradosprings.yalwa.com/ID_135741762/Eden-Oaks-Designer-Woodware.html",
      "https://colorado-springs-co.opendi.us/8333651.html",
      "https://foursquare.com/venue/5b9ccff182a750002c88aefa",
      "https://h5.m.taobao.com/trip/poi/detail/index.html?poiId=144038037",
      "https://iglobal.co/united-states/colorado-springs/eden-oaks-designer-woodware-1",
      "https://localstack.com/biz/eden-oaks-designer-woodware-colorado-springs-co/35992515",
      "https://maps.propertycapsule.com/internal/listing/1084872/profile",
      "https://nextdoor.com/pages/eden-oaks-designer-woodware-colorado-springs-co",
      "https://uscity.net/listing/eden_oaks_designer_woodware-9553298",
      "https://wheretoapp.com/search?poi=b50d267dc76ebc820c9f0126a222effc",
      "https://www.2findlocal.com/b/12836656/eden-oaks-designer-woodware-colorado-springs-co",
      "https://www.chamberofcommerce.com/united-states/colorado/colorado-springs/furniture-accessories/1338157379-eden-oaks-designer-woodware",
      "https://www.cylex.us.com/company/eden-oaks-designer-woodware-27021725.html",
      "https://www.dandb.com/businessdirectory/---200666985.html",
      "https://www.dexknows.com/business_profiles/-l2200082415",
      "https://www.elocal.com/profile/eden-oaks-designer-woodware-19700494",
      "https://www.golocal247.com/biz/eden-oaks-designer-woodware/colorado-springs-co/YEXT1870451",
      "https://www.hotfrog.com/company/1219812951531520",
      "https://www.localdatabase.com/l/eden-oaks-designer-woodware-1",
      "https://www.merchantcircle.com/eden-oaks-designer-woodware-colorado-springs-co",
      "https://www.mylocalservices.com/Colorado/Furniture/21705141/Eden_Oaks_Designer_Woodware.html",
      "https://www.showmelocal.com/profile.aspx?bid=22602471",
      "https://www.superpages.com/bp/colorado-springs-co/eden-oaks-designer-woodware-L2200082415.htm?SRC=nx87tb39&lbp=1&PGID=dfwlnpplanbt15.8083.1579284961673.1821275958695&bidType=FLCLIK&TR=77&bpp=0",
      "https://www.yasabe.com/en/colorado-springs-co/d-1000000799923-eden-oaks-designer-woodware.html",
      "https://www.yellowpagesdirectory.com/?page=show&state=CO&id=805241",
      "https://www.yelp.com/biz/eden-oaks-designer-woodware-colorado-springs-2?adjust_creative=xZR1D5sIhOfqTpbo8Ul3ow&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_search&utm_source=xZR1D5sIhOfqTpbo8Ul3ow",
    ],
    priceRange: "$$",
  }
  return (
    <Layout location={location}>
      <SeoComponents
        title="Custom Woodworking Shops Near Me - Keep In Touch"
        description="For high quality handmade wood furniture, Eden Oaks provides custom woodworking in Colorado Springs and surrounding areas. Call us to discuss your project!"
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
      </Helmet>
      <PageHeading bgImage={HeadingBG}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <h1 className="display-4 text-white">Keep in Touch</h1>
            </div>
          </div>
        </div>
      </PageHeading>
      <div className="py-10">
        <div className="container">
          <h2 className="font-weight-bold text-uppercase mb-4">Contact Us</h2>
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0 pr-md-4">
              <p className="lead mb-4">
                For questions or inquiries about our services or for general
                questions, please fill out the form and a member of our customer
                care team will be in contact with you. You may also call us on
                the number provided. Thank you.
              </p>
              <ul className="contact-details-list">
                <li className="address">
                  709 Nichols Blvd., Unit A, Colorado Springs, CO 80907
                </li>
                <li className="phone">
                  <a href="tel:719-985-3336">(719) 985-3336</a>
                </li>
                <li className="email">
                  <a href="mailto:freddie@edenoaksco.com">
                    freddie@edenoaksco.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <LazyLoadComponent>
                <ContactForm />
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3105.8324889341875!2d-104.81449978465011!3d38.88207417957303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4544c624a2aba0a7!2sEden%20Oaks%20Designer%20Woodware!5e0!3m2!1sen!2sph!4v1580365772979!5m2!1sen!2sph"
          width="100%"
          height="400"
          title="Map"
          frameborder="0"
          style={{ border: "0", marginBottom: "-10px" }}
          allowfullscreen=""
        />
      </div>
    </Layout>
  )
}
export default Contact
